import { CategoryResponse, Item } from "..";
import { Catalog, CatalogResponse, Metadata } from "./catalog";
import { getCatalogLinks } from "./getCatalogLinks";
import { getEmptyCatalog } from "./getEmptyCatalog";

export async function getItemCatalog(
  uuid: string,
  itemCode: string,
  catalogGroup: string,
  accessToken?: string,
): Promise<CatalogResponse> {
  try {
    Catalog.initClient(accessToken);

    const itemPromise = Item.getItem(itemCode, uuid, catalogGroup, accessToken);
    const linksPromise = getCatalogLinks(uuid, catalogGroup);

    const [{ itemResponse, category }, links] = await Promise.all([
      itemPromise,
      linksPromise,
    ]);

    const categoryData = await Catalog.client.get<{
      data: { categoryMenu: CategoryResponse; metadata: Metadata };
    }>(`/v1/merchants/${uuid}/catalog-category/${category.code}`, {
      params: { catalog_group: catalogGroup, items_page: 1, items_size: 12 },
    });

    const itens = [itemResponse, ...categoryData.data.data.categoryMenu.itens];

    const categoryResponse: CategoryResponse = {
      code: category.code,
      name: category.name,
      itens: itens,
      order: 1,
    };

    return {
      categories: [categoryResponse],
      links: links,
    } as CatalogResponse;
  } catch (error) {
    console.error({
      message: "Error fetching item catalog",
      context: error,
    });
    return getEmptyCatalog(uuid, catalogGroup);
  }
}

import { AisleResponse, CategoryResponse } from "..";
import { Catalog, CatalogLink } from "./catalog";

export async function getCatalogLinks(
  uuid: string,
  catalogGroup: string,
): Promise<CatalogLink[]> {
  try {
    Catalog.initClient();

    const [catalogResponse, aisleResponse] = await Promise.all([
      Catalog.client
        .get<{ data: { menu: CategoryResponse[] } }>(`/catalog/${uuid}`, {
          params: { catalog_group: catalogGroup, category_items_size: 0 },
        })
        .catch((error) => {
          console.error(error);
          return null;
        }),

      Catalog.client
        .get<AisleResponse[]>(`/aisle`, {
          params: { merchantId: uuid },
        })
        .catch((error) => {
          console.error(error);
          return null;
        }),
    ]);

    const catalogData = catalogResponse?.data?.data ?? { menu: [] };
    const aisleData = aisleResponse?.data ?? [];

    const catalogLinks = [
      ...aisleData.map((aisleItem) => ({
        uuid: aisleItem.slug,
        name: aisleItem.name,
        type: "corredor",
      })),
      ...catalogData.menu.map((category) => ({
        uuid: category.code,
        name: category.name,
        type: "categoria",
      })),
    ];

    return catalogLinks;
  } catch (error) {
    console.error({
      message: "Error fetching catalog links",
      context: error,
    });
    return [] as CatalogLink[];
  }
}

import { Catalog, CatalogResponse } from "./catalog";
import { getCatalogLinks } from "./getCatalogLinks";

export async function getEmptyCatalog(
  uuid: string,
  catalogGroup: string,
): Promise<CatalogResponse> {
  try {
    Catalog.initClient();
    const links = await getCatalogLinks(uuid, catalogGroup);
    return {
      categories: [],
      links,
    } as CatalogResponse;
  } catch (error) {
    return {
      categories: [],
      links: [],
    } as CatalogResponse;
  }
}

import { MoneyJSON } from "@app/domains/shared/models";

export type Unity = "g" | "kg" | "ml" | "l";

export enum Availability {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
}

export enum PortionSizesTags {
  NotApplicable = "NOT_APPLICABLE",
  ServesOne = "SERVES_1",
  ServesTwo = "SERVES_2",
  ServesThree = "SERVES_3",
  ServerFour = "SERVES_4",
}

export enum DietaryRestrictionsTags {
  Vegetarian = "VEGETARIAN",
  Vegan = "VEGAN",
  Organic = "ORGANIC",
  GlutenFree = "GLUTEN_FREE",
  SugarFree = "SUGAR_FREE",
  LacFree = "LAC_FREE",
  AlcoholicDrink = "ALCOHOLIC_DRINK",
}

export enum ProductTagGroup {
  PortionSize = "PORTION_SIZE",
  DietaryRestriction = "DIETARY_RESTRICTIONS",
}

export type ProductInfo = {
  id: string;
  quantity: number;
  unit: Unity;
};

export type SellingOption = {
  minimum: number;
  incremental: number;
  availableUnits: string[];
};

export type ItemMiscellaneous = {
  wentThroughPipeline: boolean;
  overrideItemVisibility: boolean;
  originalIncrementalWeight: number;
  rawPrice: {
    price: string;
  };
};

export type DietaryRestrictionTag = {
  group: ProductTagGroup.DietaryRestriction;
  tags: Array<DietaryRestrictionsTags>;
};

export type PortionSizeTag = {
  group: ProductTagGroup.PortionSize;
  tags: Array<PortionSizesTags>;
};

export type ProductTag = DietaryRestrictionTag | PortionSizeTag;

export type ItemResponse = {
  id: string;
  code: string;
  details: string;
  description: string;
  unitPrice: number;
  unitMinPrice: number;
  unitOriginalPrice?: number;
  promotionalPrice?: number;
  minimumPromotionalPrice?: number;
  logoUrl?: string;
  productInfo?: ProductInfo;
  productTags?: ProductTag[];
  tags?: string[];
  needChoices?: boolean;
  sellingOption?: SellingOption;
  itemMiscellaneous?: string;
  additionalInfo?: string;
  order: number;
};

export type ItemJSON = {
  id: string;
  code: string;
  category: string;
  categoryCode: string;
  details: string;
  description: string;
  unitPrice: MoneyJSON;
  unitMinPrice: MoneyJSON;
  unitOriginalPrice?: MoneyJSON;
  promotionalPrice?: MoneyJSON;
  minimumPromotionalPrice?: MoneyJSON;
  logoUrl?: string;
  productInfo?: ProductInfo;
  productTags?: ProductTag[];
  tags?: string[];
  sellingOption?: SellingOption;
  itemMiscellaneous?: ItemMiscellaneous;
  additionalInfo?: string;
  order: number;
};

import { CategoryResponse } from "..";
import { Catalog, CatalogResponse } from "./catalog";
import { getCatalogLinks } from "./getCatalogLinks";
import { getEmptyCatalog } from "./getEmptyCatalog";

export async function getHomeCatalog(
  uuid: string,
  catalogGroup: string,
  accessToken?: string,
): Promise<CatalogResponse> {
  try {
    Catalog.initClient(accessToken);

    const linksPromise = getCatalogLinks(uuid, catalogGroup);
    const catalogPromise = Catalog.client.get<{
      data: { menu: CategoryResponse[] };
    }>(`/catalog/${uuid}`, {
      params: { catalog_group: catalogGroup, category_items_size: 12 },
    });

    const [catalogData, links] = await Promise.all([
      catalogPromise,
      linksPromise,
    ]);

    return {
      categories: catalogData.data.data?.menu ?? [],
      links,
    } as CatalogResponse;
  } catch (error) {
    console.error({
      message: "Error fetching home catalog",
      context: error,
    });
    return getEmptyCatalog(uuid, catalogGroup);
  }
}

import { ItemResponse } from "..";
import { Catalog, CatalogResponse } from "./catalog";
import { getCatalogLinks } from "./getCatalogLinks";

export async function getSearchCatalog(
  uuid: string,
  catalogGroup: string,
  term: string,
  page?: number,
  accessToken?: string,
): Promise<CatalogResponse> {
  Catalog.initClient(accessToken);

  const linksPromise = getCatalogLinks(uuid, catalogGroup);
  const searchPromise = Catalog.client.get<{
    items: { data: any[] };
  }>(`/search/catalog-items`, {
    params: {
      item_from_merchant_ids: uuid,
      catalog_group: catalogGroup,
      page: page ?? 0,
      size: 48,
      term,
    },
  });

  const [searchResult, links] = await Promise.all([
    searchPromise,
    linksPromise,
  ]);

  const itens: ItemResponse[] = searchResult.data.items.data.map(
    (searchItem, index) => {
      return {
        order: index,
        id: searchItem.id,
        code: searchItem.code,
        details: searchItem.description,
        description: searchItem.name,
        unitPrice: searchItem.price,
        unitMinPrice: searchItem.price,
        unitOriginalPrice: searchItem.originalPrice || searchItem.price,
        promotionalPrice: searchItem.promotionalPrice,
        minimumPromotionalPrice: searchItem.minimumPromotionalPrice,
        itemMiscellaneous: searchItem.itemMiscellaneous ?? null,
        sellingOption: searchItem.sellingOption ?? null,
        productInfo: searchItem.productInfo ?? null,
        logoUrl:
          searchItem.resources.length > 0
            ? searchItem.resources.at(0).fileName
            : null,
      } as ItemResponse;
    },
  );

  return {
    categories: [
      {
        order: 1,
        code: term,
        itens: itens,
        name: "Resultados da busca",
      },
    ],
    links,
  } as CatalogResponse;
}
